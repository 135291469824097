<template>
  <v-dialog v-model="showDialog" max-width="660px" scrollable>
    <v-card style="padding: 25px" elevation="0" outlined>
      <v-card-text>
        <div class="d-flex align-center justify-space-between">
          <span class="text-h6 font-weight-bold">{{
            convertStateLang(stateAction)
          }}</span>
          <v-btn v-if="update" dark small color="primary" @click="newMedicine">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </div>
        <v-form ref="form" lazy-validation v-model="validForm">
          <!-- Medicine Name -->
          <v-text-field
            v-model="dataCreateUpdate.name"
            :rules="rules.name"
            :label="$t('main.medicine.medicineForm.name')"
            required
          ></v-text-field>
          <!-- Medicine Description -->
          <v-text-field
            v-model="dataCreateUpdate.description"
            :rules="rules.description"
            :label="$t('main.medicine.medicineForm.description')"
            required
          ></v-text-field>

          <v-row class="pa-0 mt-2">
            <!-- Medicine route -->
            <v-col cols="12">
              <div class="d-flex flex-column">
                <span class="font-weight-bold">{{
                  $t("main.medicine.medicineForm.medicineRoute")
                }}</span>
                <div class="d-flex align-center pa-0">
                  <v-select
                    v-model="dataCreateUpdate.defaultMedRoute"
                    :items="medicineRouteItems"
                    item-text="vname"
                    item-value="id"
                    :label="$t('main.medicine.medicineForm.medicineRoute')"
                    single-line
                  ></v-select>
                </div>
              </div>
            </v-col>

            <!-- usageNumber - UnitType -->
            <v-col cols="12">
              <div class="d-flex flex-column">
                <span class="font-weight-bold">{{
                  $t("main.medicine.medicineForm.unitNT")
                }}</span>
                <div class="d-flex">
                  <v-text-field
                    class="mr-2 text-center"
                    v-model="dataCreateUpdate.defaultUsageNum"
                    required
                    :rules="rules.defaultUsageNum"
                  ></v-text-field>
                  <v-select
                    v-model="dataCreateUpdate.unitType"
                    :items="unitTypeItems"
                    item-text="vname"
                    item-value="id"
                    :label="$t('main.medicine.medicineForm.unitType')"
                    class="ml-2"
                    single-line
                  ></v-select>
                </div>
              </div>
            </v-col>
            <!-- Dose per day -->
            <v-col cols="12">
              <div class="d-flex flex-column">
                <span class="font-weight-bold">{{
                  $t("main.medicine.medicineForm.perDay")
                }}</span>
                <div class="d-flex align-center">
                  <v-text-field
                    class="mr-1"
                    v-model="dataCreateUpdate.defaultDosePerDay"
                    :rules="rules.perInDay"
                    :label="$t('main.medicine.medicineForm.requestQ')"
                    required
                  ></v-text-field>

                  <span class="ml-1 font-weight-bold">{{
                    $t("main.medicine.medicineForm.timeADay")
                  }}</span>
                </div>
              </div>
            </v-col>

            <!--  AmountBuying & Unit Buying-->
            <v-col cols="12">
              <div class="d-flex flex-column">
                <span class="font-weight-bold">{{
                  $t("main.medicine.medicineForm.amountBuyUnit")
                }}</span>
                <div class="d-flex">
                  <v-text-field
                    class="mr-2 text-center"
                    v-model="dataCreateUpdate.defaultBuyingNumber"
                    required
                    :label="$t('main.medicine.medicineForm.total')"
                    :rules="rules.amountBuying"
                  ></v-text-field>
                  <v-select
                    v-model="dataCreateUpdate.defaultBuyingUnit"
                    :items="unitBuyingItems"
                    item-text="vname"
                    item-value="id"
                    label="Unit type"
                    class="ml-2"
                    single-line
                  ></v-select>
                </div>
              </div>
            </v-col>

            <!-- Status -->
            <v-col cols="12" v-if="update">
              <div class="d-flex flex-column">
                <span class="font-weight-bold">{{
                  $t("main.medicine.medicineForm.status")
                }}</span>
                <div class="d-flex">
                  <v-select
                    v-model="dataCreateUpdate.statusID"
                    :items="StatusItems"
                    item-text="text"
                    item-value="value"
                    label="Status"
                    single-line
                  ></v-select>
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- Action -->
          <div class="d-flex justify-space-between">
            <v-btn class="white--text" color="#fb8c00" small @click="reset">
              Reset
            </v-btn>
            <v-btn class="white--text" color="#1867c0" small @click="save">
              {{ $t("common.Save") }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import {
  MedicineUnit,
  MedicineBuyingUnit,
  MedicineRoute,
} from "@/components/common/Medication/config";
import { StatusItems } from "@/plugins/constant";
import MedicineService from "@/services/medicine";
import i18n from "@/plugins/i18n";

export default {
  name: "MedicineContainerComponent",
  components: {},
  watch: {},
  computed: {
    stateAction() {
      return this.create ? "New Medicine" : "Edit Medicine";
    },
  },
  created() {},
  data: () => ({
    create: true,
    update: false,
    showDialog: false,
    list: {
      keySearch: "",
      headers: [
        { text: i18n.t("main.medicine.medicineTable.name"), value: "name" },
        {
          text: i18n.t("main.medicine.medicineTable.description"),
          value: "description",
        },
        {
          text: i18n.t("main.medicine.medicineTable.medicineRoute"),
          value: "defaultMedRoute",
        },
        {
          text: i18n.t("main.medicine.medicineTable.unitTime"),
          value: "unit_time",
        },
        {
          text: i18n.t("main.medicine.medicineTable.timeDay"),
          value: "defaultDosePerDay",
        },
        {
          text: i18n.t("main.medicine.medicineTable.status"),
          value: "statusID",
        },
      ],
      totals: 0,
      items: [],
      loading: false,
      options: {},
    },

    validForm: false,
    dataCreateUpdate: {
      medicineID: "",
      name: "",
      description: "",
      defaultMedRoute: 1,
      defaultUsageNum: 1,
      unitType: 1,
      defaultDosePerDay: 1,
      defaultAmountPerDose: 1,
      defaultBuyingNumber: 1,
      defaultBuyingUnit: 1,
      statusID: 1,
    },
    dataEdit: {},

    unitTypeItems: _.sortBy(MedicineUnit, {
      function(o) {
        return o.vname;
      },
    }),
    unitBuyingItems: _.sortBy(MedicineBuyingUnit, {
      function(o) {
        return o.vname;
      },
    }),
    medicineRouteItems: _.sortBy(MedicineRoute, {
      function(o) {
        return o.vname;
      },
    }),

    StatusItems,

    rules: {
      name: [
        (v) => !!v || i18n.t("main.medicine.medicineForm.validation.nameRe"),
        (v) =>
          (v && v.length >= 3) ||
          i18n.t("main.medicine.medicineForm.validation.nameRe3"),
      ],
      description: [
        (v) =>
          !!v || i18n.t("main.medicine.medicineForm.validation.descriptionRe"),
        (v) =>
          (v && v.length >= 3) ||
          i18n.t("main.medicine.medicineForm.validation.InputRe"),
      ],
      defaultUsageNum: [
        (v) => !!v || i18n.t("main.medicine.medicineForm.validation.usageRe"),
        (v) =>
          /^[0-9]*$/.test(v) ||
          i18n.t("main.medicine.medicineForm.validation.invalidFormat"),
      ],
      unitType: [
        (v) =>
          !!v || i18n.t("main.medicine.medicineForm.validation.typeUnitRe"),
      ],
      perInDay: [
        (v) => !!v || i18n.t("main.medicine.medicineForm.validation.perDayRe"),
        (v) =>
          /^[0-9]*$/.test(v) ||
          i18n.t("main.medicine.medicineForm.validation.invalidFormat"),
      ],
      amountBuying: [
        (v) => !!v || i18n.t("main.medicine.medicineForm.validation.perDayRe"),
        (v) =>
          /^[0-9]*$/.test(v) ||
          i18n.t("main.medicine.medicineForm.validation.invalidFormat"),
      ],
    },
  }),

  methods: {
    async openPopup(item) {
      this.showDialog = true;
      this.editMedicine(item);
    },
    // ----  Click POP UP creation ----
    async openCreate() {
      this.showDialog = true;
      this.medicineID = null;
    },
    reset() {
      if (this.create) {
        this.refreshDataCreation();
        return;
      }
      if (this.update) {
        this.dataCreateUpdate = this.dataEdit;
        return;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        // Creation
        if (this.create) {
          var result = await MedicineService.create(this.dataCreateUpdate);
          if (result.error) {
            this.$toast.error("Can not create a new medicine!");
            return;
          }
          // search medicine Again!
          this.$toast.success(i18n.t("message.popUpMedicine"));
          this.refreshDataCreation();
          this.$emit("onRenderSearchList");

          return;
        }
        // Updating
        if (this.update) {
          var resultUpdating = await MedicineService.update(
            this.dataCreateUpdate.medicineID,
            this.dataCreateUpdate
          );
          if (resultUpdating.error) {
            this.$toast.error("Can not update medicine!");
            return;
          }
          // search again
          this.$toast.success("Update successfully!");
          this.$emit("onRenderSearchList");

          this.dataEdit = _.cloneDeep(this.dataCreateUpdate);
          return;
        }
      } else {
        this.$toast.error("Maybe some fields need to be filled!");
        return;
      }
    },
    convertStateLang(text) {
      var val = "";
      switch (text) {
        case "New Medicine":
          val = i18n.t("main.medicine.newMedicine");
          break;
        case "Edit Medicine":
          val = i18n.t("main.medicine.editMedicine");
      }
      return val;
    },
    printMedicationRoute(id) {
      let medicineRoute = _.find(this.medicineRouteItems, { id });
      return medicineRoute.vname;
    },

    numberUnitTime(item) {
      let unitType = _.find(this.unitTypeItems, { id: item.unitType });
      return `${item.defaultUsageNum} ${unitType.vname}`;
    },

    editMedicine(item) {
      this.create = false;
      this.update = true;
      // parse data
      this.dataEdit = _.cloneDeep(item);
      this.dataCreateUpdate = _.cloneDeep(item);
    },

    newMedicine() {
      this.create = true;
      this.update = false;
      // parse default data
      this.dataEdit = {};
      this.refreshDataCreation();
    },

    refreshDataCreation() {
      this.dataCreateUpdate = {
        medicineID: "",
        name: "",
        description: "",
        defaultMedRoute: 1,
        defaultUsageNum: 1,
        unitType: 1,
        defaultDosePerDay: 1,
        defaultAmountPerDose: 1,
        defaultBuyingNumber: 1,
        defaultBuyingUnit: 1,
        statusID: 1,
      };
    },
  },
};
</script>

<style scoped>
.text-space {
  letter-spacing: 0.5px;
}
</style>