<template>
  <span>
    <b v-if="status == StatusCodes.Active" 
      class="blue--text"
      >
      Active 
    </b>
    <b v-else-if="status == StatusCodes.Deleted"
      class="red--text"
    >
      Delete
    </b>
  </span>
  
</template>

<script>
import  { StatusCodes }  from '@/plugins/constant'
export default {
  name: "BaseStatusComponent",
  props: {
    status: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    StatusCodes
  })
}
</script>

<style>

</style>