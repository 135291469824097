// đường dùng
export const MedicineRoute = [ // đường dùng
  {
    id: 1,
    name: 'Orally',
    vname: 'Uống',
  },
  { 
    id: 2,
    name: 'Drop',
    vname: 'Nhỏ',
  },
  { 
    id: 3,
    name: 'Apply',
    vname: 'Tra'
  },
  {
    id: 4,
    name: 'Injection',
    vname: 'Tiêm nội nhãn'
  },
  {
    id: 5,
    name: 'Warm compresses',
    vname: 'Chườm ấm'
  },
  {
    id: 6,
    name: 'Apply to skin',
    vname: 'Thoa'
  },
  {
    id: 7,
    name: 'Apply to skin',
    vname: 'Bôi da'
  },
  {
    id: 8,
    name: 'Suck',
    vname: 'Ngậm'
  },
  {
    id: 8,
    name: 'Wash eyelashes',
    vname: 'Rửa bờ mi'
  },
  {
    id: 9,
    name: 'Retrobulba Injection',
    vname: 'Tiêm cạnh cầu'
  },
  {
    id: 10,
    name: 'Injection',
    vname: 'Tiêm'
  },
  {
    id: 11,
    name: 'Intravenous infusion',
    vname: 'Tiêm truyền tĩnh mạch'
  },
  {
    id: 12,
    name: 'Spray',
    vname: 'Xịt'
  },
  {
    id: 13,
    name: 'For external use',
    vname: 'Dùng ngoài'
  },
  {
    id: 14,
    name: 'Apply to eyelid',
    vname: 'Bôi bờ mi'
  }
]

// đơn vị nhỏ nhất - 3 viên, 3 gói, 3 miếng
export const MedicineUnit = [
  {
    id: 1,
    name: 'cm',
    vname: 'cm',
  },
  {
    id: 2,
    name: 'drop',
    vname: 'giọt',
  },
  {
    id: 3,
    name: 'sachet',
    vname: 'gói',
  },
  {
    id: 4,
    name: 'ml',
    vname: 'ml',
  },
  {
    id: 5,
    name: 'piece',
    vname: 'miếng',
  },
  {
    id: 6,
    name: 'stroke',
    vname: 'nhát',
  },
  {
    id: 7,
    name: 'tube',
    vname: 'ống',
  },
  {
    id: 8,
    name: 'tablet',
    vname: 'viên',
  },
]

// đơn vị lón nhất - 1 gói, 60 viên, 1 hộp
export const MedicineBuyingUnit = [
  {
    id: 1,
    vname: 'chai',
    name: 'bottle'
  },
  {
    id: 2,
    vname: 'gói',
    name: 'sachet'
  },
  {
    id: 3,
    vname: 'hôp',
    name: 'box'
  },
  {
    id: 4,
    vname: 'lọ',
    name: 'bottle'
  },
  {
    id: 5,
    vname: 'ống',
    name: 'tube'
  },
  {
    id: 6,
    vname: 'tuýp',
    name: 'tube'
  },
  {
    id: 7,
    vname: 'viên',
    name: 'tablet'
  },

];


// 1 thuốc phải thỏa
// thuốc ABC - đương dùng: Uống, 3 lần/ngày, 3 viên/lần, trong 1 ngày, số lượng: 10 viên 

const MedForDrink = ['sau ăn', 'trước ăn'];
const MedForUse = ['mắt phải', 'mắt trái', 'hai mắt'];

export const ConfigMedForRoute = {
  1: MedForDrink,
  2: MedForUse, 
  3: MedForUse,
  4: MedForUse,
  5: MedForUse,
  6: MedForUse,
  7: MedForUse,
  8: MedForDrink,
  9: MedForUse,
  10: MedForUse,
  11: MedForUse,
  12: MedForUse,
  13: MedForUse,
  14: MedForUse
}

export const AllowPlusMedicineUnit = [5,7,8]; // id from medicine Unit