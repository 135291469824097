<template>
  <Container>
    <v-row>
      <!-- List Medicine -->
      <v-col lg="8" sm="12" sx="12">
        <v-card elevation="0">
          <v-card-title>
            <div class="w-100">
              <span class="text-h6 font-weight-bold">{{
                $t("main.medicine.listMedicine")
              }}</span>
              <v-row class="fit-content">
                <v-text-field
                  v-model="list.keySearch"
                  :label="$t('main.medicine.keySearch')"
                  class="w-fit-low-rs-7"
                ></v-text-field>
                <v-btn
                  depressed
                  color="#0065FF"
                  style="display: none"
                  class="white--text pl-10 pr-10 w-fit-low-rs-3 dp-block-low-resolution btn-fit"
                  @click="openMedicineDetail"
                >
                  {{ $t("main.medicine.add") }}
                </v-btn>
              </v-row>
            </div>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-data-table
              :headers="list.headers"
              :items="list.items"
              :options.sync="list.options"
              :server-items-length="list.totals"
              :search="list.keySearch"
              :loading="list.loading"
            >
              <template v-slot:[`item.name`]="{ item }">
                <span
                  class="blue--text"
                  style="cursor: pointer"
                  @click="editMedicine(item)"
                  >{{ item.name }}</span
                >
              </template>
              <template v-slot:[`item.unit_time`]="{ item }">
                {{ numberUnitTime(item) }}
              </template>
              <template v-slot:[`item.defaultMedRoute`]="{ item }">
                {{ printMedicationRoute(item.defaultMedRoute) }}
              </template>
              <template v-slot:[`item.statusID`]="{ item }">
                <BaseStatus :status="parseInt(item.statusID)" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Add new medicine -->
      <v-col lg="4" sm="12" sx="12" class="dp-none-low-resolution">
        <v-card elevation="0" outlined>
          <v-card-text>
            <div class="d-flex align-center justify-space-between">
              <span class="text-h6 font-weight-bold">{{
                convertStateLang(stateAction)
              }}</span>
              <v-btn
                v-if="update"
                dark
                small
                color="primary"
                @click="newMedicine"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
            <v-form ref="form" lazy-validation v-model="validForm">
              <!-- Medicine Name -->
              <v-text-field
                v-model="dataCreateUpdate.name"
                :rules="rules.name"
                :label="$t('main.medicine.medicineForm.name')"
                required
              ></v-text-field>
              <!-- Medicine Description -->
              <v-text-field
                v-model="dataCreateUpdate.description"
                :rules="rules.description"
                :label="$t('main.medicine.medicineForm.description')"
                required
              ></v-text-field>

              <v-row class="pa-0 mt-2">
                <!-- Medicine route -->
                <v-col cols="12">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold">{{
                      $t("main.medicine.medicineForm.medicineRoute")
                    }}</span>
                    <div class="d-flex align-center pa-0">
                      <v-select
                        v-model="dataCreateUpdate.defaultMedRoute"
                        :items="medicineRouteItems"
                        item-text="vname"
                        item-value="id"
                        :label="$t('main.medicine.medicineForm.medicineRoute')"
                        single-line
                      ></v-select>
                    </div>
                  </div>
                </v-col>

                <!-- usageNumber - UnitType -->
                <v-col cols="12">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold">{{
                      $t("main.medicine.medicineForm.unitNT")
                    }}</span>
                    <div class="d-flex">
                      <v-text-field
                        class="mr-2 text-center"
                        v-model="dataCreateUpdate.defaultUsageNum"
                        required
                        :rules="rules.defaultUsageNum"
                      ></v-text-field>
                      <v-select
                        v-model="dataCreateUpdate.unitType"
                        :items="unitTypeItems"
                        item-text="vname"
                        item-value="id"
                        :label="$t('main.medicine.medicineForm.unitType')"
                        class="ml-2"
                        single-line
                      ></v-select>
                    </div>
                  </div>
                </v-col>
                <!-- Dose per day -->
                <v-col cols="12">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold">{{
                      $t("main.medicine.medicineForm.perDay")
                    }}</span>
                    <div class="d-flex align-center">
                      <v-text-field
                        class="mr-1"
                        v-model="dataCreateUpdate.defaultDosePerDay"
                        :rules="rules.perInDay"
                        :label="$t('main.medicine.medicineForm.requestQ')"
                        required
                      ></v-text-field>

                      <span class="ml-1 font-weight-bold">{{
                        $t("main.medicine.medicineForm.timeADay")
                      }}</span>
                    </div>
                  </div>
                </v-col>

                <!--  AmountBuying & Unit Buying-->
                <v-col cols="12">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold">{{
                      $t("main.medicine.medicineForm.amountBuyUnit")
                    }}</span>
                    <div class="d-flex">
                      <v-text-field
                        class="mr-2 text-center"
                        v-model="dataCreateUpdate.defaultBuyingNumber"
                        required
                        :label="$t('main.medicine.medicineForm.total')"
                        :rules="rules.amountBuying"
                      ></v-text-field>
                      <v-select
                        v-model="dataCreateUpdate.defaultBuyingUnit"
                        :items="unitBuyingItems"
                        item-text="vname"
                        item-value="id"
                        label="Unit type"
                        class="ml-2"
                        single-line
                      ></v-select>
                    </div>
                  </div>
                </v-col>

                <!-- Status -->
                <v-col cols="12" v-if="update">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold">{{
                      $t("main.medicine.medicineForm.status")
                    }}</span>
                    <div class="d-flex">
                      <v-select
                        v-model="dataCreateUpdate.statusID"
                        :items="StatusItems"
                        item-text="text"
                        item-value="value"
                        label="Status"
                        single-line
                      ></v-select>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <!-- Action -->
              <div class="d-flex justify-space-between">
                <v-btn class="white--text" color="#fb8c00" small @click="reset">
                  Reset
                </v-btn>
                <v-btn class="white--text" color="#1867c0" small @click="save">
                  {{ $t("common.Save") }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <PopupDetailMedicine
      @onRenderSearchList="searchMedicine"
      ref="PopupDetailMedicine"
    ></PopupDetailMedicine>
  </Container>
</template>

<script>
import _ from "lodash";
import BaseStatus from "@/components/common/BaseStatus";
import { Container } from "./css/style";
import {
  MedicineUnit,
  MedicineBuyingUnit,
  MedicineRoute,
} from "@/components/common/Medication/config";
import { StatusItems } from "@/plugins/constant";
import MedicineService from "@/services/medicine";
import PopupDetailMedicine from "@/components/common/Medicine/PopupDetailMedicine";
import i18n from "@/plugins/i18n";

export default {
  name: "MedicineContainerComponent",
  components: {
    Container,
    BaseStatus,
    PopupDetailMedicine,
  },
  watch: {
    "list.options": {
      deep: true,
      handler() {
        this.searchMedicine();
      },
    },
    "list.keySearch": {
      deep: true,
      handler() {
        this.searchMedicine();
      },
    },
  },
  computed: {
    stateAction() {
      return this.create ? "New Medicine" : "Edit Medicine";
    },
  },
  created() {},
  data: () => ({
    create: true,
    update: false,
    statusConvertLang: "",
    list: {
      keySearch: "",
      headers: [
        { text: i18n.t("main.medicine.medicineTable.name"), value: "name" },
        {
          text: i18n.t("main.medicine.medicineTable.description"),
          value: "description",
        },
        {
          text: i18n.t("main.medicine.medicineTable.medicineRoute"),
          value: "defaultMedRoute",
        },
        {
          text: i18n.t("main.medicine.medicineTable.unitTime"),
          value: "unit_time",
        },
        {
          text: i18n.t("main.medicine.medicineTable.timeDay"),
          value: "defaultDosePerDay",
        },
        {
          text: i18n.t("main.medicine.medicineTable.status"),
          value: "statusID",
        },
      ],
      totals: 0,
      items: [],
      loading: false,
      options: {},
    },

    validForm: false,
    dataCreateUpdate: {
      medicineID: "",
      name: "",
      description: "",
      defaultMedRoute: 1,
      defaultUsageNum: 1,
      unitType: 1,
      defaultDosePerDay: 1,
      defaultAmountPerDose: 1,
      defaultBuyingNumber: 1,
      defaultBuyingUnit: 1,
      statusID: 1,
    },
    dataEdit: {},

    unitTypeItems: _.sortBy(MedicineUnit, {
      function(o) {
        return o.vname;
      },
    }),
    unitBuyingItems: _.sortBy(MedicineBuyingUnit, {
      function(o) {
        return o.vname;
      },
    }),
    medicineRouteItems: _.sortBy(MedicineRoute, {
      function(o) {
        return o.vname;
      },
    }),

    StatusItems,

    rules: {
      name: [
        (v) => !!v || i18n.t("main.medicine.medicineForm.validation.nameRe"),
        (v) =>
          (v && v.length >= 3) ||
          i18n.t("main.medicine.medicineForm.validation.nameRe3"),
      ],
      description: [
        (v) =>
          !!v || i18n.t("main.medicine.medicineForm.validation.descriptionRe"),
        (v) =>
          (v && v.length >= 3) ||
          i18n.t("main.medicine.medicineForm.validation.InputRe"),
      ],
      defaultUsageNum: [
        (v) => !!v || i18n.t("main.medicine.medicineForm.validation.usageRe"),
        (v) =>
          /^[0-9]*$/.test(v) ||
          i18n.t("main.medicine.medicineForm.validation.invalidFormat"),
      ],
      unitType: [
        (v) =>
          !!v || i18n.t("main.medicine.medicineForm.validation.typeUnitRe"),
      ],
      perInDay: [
        (v) => !!v || i18n.t("main.medicine.medicineForm.validation.perDayRe"),
        (v) =>
          /^[0-9]*$/.test(v) ||
          i18n.t("main.medicine.medicineForm.validation.invalidFormat"),
      ],
      amountBuying: [
        (v) => !!v || i18n.t("main.medicine.medicineForm.validation.perDayRe"),
        (v) =>
          /^[0-9]*$/.test(v) ||
          i18n.t("main.medicine.medicineForm.validation.invalidFormat"),
      ],
    },
  }),

  methods: {
    openMedicineDetail() {
      this.$refs.PopupDetailMedicine.openCreate();
    },
    reset() {
      if (this.create) {
        this.refreshDataCreation();
        return;
      }
      if (this.update) {
        this.dataCreateUpdate = this.dataEdit;
        return;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        // Creation
        if (this.create) {
          var result = await MedicineService.create(this.dataCreateUpdate);
          if (result.error) {
            this.$toast.error("Can not create a new medicine!");
            return;
          }
          // search medicine Again!
          this.$toast.success(i18n.t("message.medicineContainer"));
          this.refreshDataCreation();
          this.searchMedicine();
          return;
        }
        // Updating
        if (this.update) {
          var resultUpdating = await MedicineService.update(
            this.dataCreateUpdate.medicineID,
            this.dataCreateUpdate
          );
          if (resultUpdating.error) {
            this.$toast.error("Can not update medicine!");
            return;
          }
          // search again
          this.$toast.success("Update successfully!");
          this.searchMedicine();
          this.dataEdit = _.cloneDeep(this.dataCreateUpdate);
          return;
        }
      } else {
        this.$toast.error("Maybe some fields need to be filled!");
        return;
      }
    },

    async searchMedicine() {
      this.list.loading = true;
      const { page, itemsPerPage } = this.list.options;
      var result = await MedicineService.search(
        this.list.keySearch,
        page,
        itemsPerPage
      );

      if (result.error) {
        this.list.loading = false;
        return;
      }
      this.list.loading = false;
      // parse data
      this.list.items = result.items;
      this.list.totals = result.totals;
    },

    printMedicationRoute(id) {
      let medicineRoute = _.find(this.medicineRouteItems, { id });
      return medicineRoute.vname;
    },

    numberUnitTime(item) {
      let unitType = _.find(this.unitTypeItems, { id: item.unitType });
      return `${item.defaultUsageNum} ${unitType.vname}`;
    },

    editMedicine(item) {
      var windowWidth = window.screen.width;
      if (windowWidth < 1367) {
        this.$refs.PopupDetailMedicine.openPopup(item);
      } else {
        this.create = false;
        this.update = true;
        // parse data
        this.dataEdit = _.cloneDeep(item);
        this.dataCreateUpdate = _.cloneDeep(item);
      }
    },

    newMedicine() {
      this.create = true;
      this.update = false;
      // parse default data
      this.dataEdit = {};
      this.refreshDataCreation();
    },
    convertStateLang(text) {
      var val = "";
      switch (text) {
        case "New Medicine":
          val = i18n.t("main.medicine.newMedicine");
          break;
        case "Edit Medicine":
          val = i18n.t("main.medicine.editMedicine");
      }
      return val;
    },
    convertLangStatus() {
      console.log(this.StatusItems);
    },

    refreshDataCreation() {
      this.dataCreateUpdate = {
        medicineID: "",
        name: "",
        description: "",
        defaultMedRoute: 1,
        defaultUsageNum: 1,
        unitType: 1,
        defaultDosePerDay: 1,
        defaultAmountPerDose: 1,
        defaultBuyingNumber: 1,
        defaultBuyingUnit: 1,
        statusID: 1,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.v-text-field__slot {
  text-align: center;
}
.fit-content {
  padding: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 1366px) {
  .dp-none-low-resolution {
    display: none;
  }
  .dp-block-low-resolution {
    display: block !important;
  }
  .w-fit-low-rs-7 {
    width: 55% !important;
    padding-right: 35px;
  }
  .w-fit-low-rs-3 {
    width: 20% !important;
  }
  .btn-fit {
    float: left;
  }
}
</style>